import { atomWithStorage, createJSONStorage } from 'jotai/utils';

// Use as sesssion storage so that the value is cleared when the tab is closed
// Create a generic storage function that can handle different types
const createSessionStorage = <T>() =>
  createJSONStorage<T>(() => sessionStorage);

// Create separate storage instances for different types
const stringArrayStorage = createSessionStorage<string[]>();
const dateOrNullStorage = createSessionStorage<Date | null>();

export const toastsShownPerSessionAtom = atomWithStorage<string[]>(
  'toastsShownPerSession',
  [],
  stringArrayStorage
);

export const toastAddToCartAtom = atomWithStorage<Date | null>(
  'toastAddToCart',
  null,
  dateOrNullStorage
);
