import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import {
  components,
  paragraphs,
} from '@/components/sections/PortableTextComponents';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import { useCallback, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

export default function FAQAccordion(props: {
  heading?: PortableTextBlock[];
  textContent?: PortableTextBlock[];
}) {
  const heading = props.heading;
  const textContent = props.textContent;

  const [open, setOpen] = useState(0);

  const handleOpen = useCallback(
    (value: any) => setOpen(open === value ? 0 : value),
    [open]
  );

  const Icon = useCallback(({ id, open }: { id: any; open: number }) => {
    return (
      <CaretDown
        weight='bold'
        className={`${
          id === open ? 'rotate-180' : ''
        } h-6 w-6 transition-transform`}
      />
    );
  }, []);

  return (
    <Accordion
      open={open === 1}
      icon={<Icon id={1} open={open} />}
      className='mb-2 rounded-lg outline-none'
    >
      <AccordionHeader
        onClick={() => handleOpen(1)}
        className={` ${open ? 'rounded-t-lg' : 'rounded-lg'} border border-yellow-400 bg-gradient-to-r from-amber-300/80 to-amber-200 px-5 py-4 text-lg text-charcoal md:text-xl ${
          open === 1 ? 'text-charcoal' : ''
        }`}
      >
        {heading && <PortableText value={heading} components={components} />}
      </AccordionHeader>
      <AccordionBody
        className={`rounded-b-lg border border-yellow-400 bg-amber-100 px-5 py-4 text-base font-normal text-charcoal [&_a]:underline hover:[&_a]:font-medium`}
      >
        {textContent && (
          <PortableText value={textContent} components={paragraphs} />
        )}
      </AccordionBody>
    </Accordion>
  );
}
