import React from 'react';

// Assuming a simple type for the table node
interface TableRow {
  _type: string;
  _key: string;
  cells: string[];
}

interface TableNode {
  rows?: TableRow[]; // Adjust the type based on your actual data structure
}

const YourTableComponent: React.FC<{
  node: TableNode;
}> = ({ node }) => {
  const rows = node?.rows || [];

  return (
    <div className='mb-5 mt-6 flow-root'>
      <div className='overflow-x-auto px-0.5'>
        <div className='inline-block min-w-full py-2 align-middle'>
          <div className='overflow-hidden rounded-lg ring-1 ring-gray-400'>
            <table className='not-prose min-w-full divide-y divide-gray-400 bg-white'>
              <thead className=''>
                {rows[0] && (
                  <tr className='p divide-x divide-gray-400'>
                    {rows[0].cells.map((cell, cellIndex) => (
                      <th
                        className={`py-5 pl-5 pr-5 text-sm font-bold text-charcoal sm:pl-6`}
                        key={cellIndex}
                      >
                        {cell}
                      </th>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody className='divide-y divide-gray-400 bg-white'>
                {rows.map((row, rowIndex: number) =>
                  rowIndex > 0 ? (
                    <tr
                      className='divide-x divide-gray-400'
                      key={row._key || rowIndex}
                    >
                      {row.cells.map((cell, cellIndex) => (
                        <td
                          className={`py-5 pl-5 pr-5 text-sm font-normal text-charcoal sm:pl-6`}
                          key={cellIndex}
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourTableComponent;
